<template>
  <div class="card_post">
    <!-- <div class="title_box"> -->
      <!-- <p id="content" class="inter-normal-fuscous-gray-m">{{ content }}</p> -->
    <!-- </div> -->
    <img v-if="type == 'image'" :key="id" @error="aboutError(id)" class="image" :src="link" />
    <video v-else-if="id" :class="'image lazy' + id" autoplay muted loop id="myVideo" @loadeddata="emit()">
      <source :data-src="link" type="video/mp4">
    </video>
    <video v-else class="image lazy" autoplay muted loop id="myVideo" >
      <source :data-src="link" type="video/mp4">
    </video>
    
    
    <p id="title" class="inter-medium-fuscous-gray-m" v-html="title"></p>
    <p id="content" class="inter-medium-fuscous-gray-s" v-html="content"></p>
  </div>
</template>
  
<script>
export default {
  name: "postCard",
  props: ['link', 'title', 'content', 'type', 'id'],
  mounted() {
    

    let cls = "lazy";
    if(this.id) {
      cls = "lazy" + this.id;
    }
    if(this.type == 'video') {
    //document.addEventListener("DOMContentLoaded", function() {
        var lazyVideos = [].slice.call(document.querySelectorAll("video." + cls));
        if ("IntersectionObserver" in window) {
          var lazyVideoObserver = new IntersectionObserver(function(entries) {
            entries.forEach(function(video) {
              if (video.isIntersecting) {
                console.log("loaded " + cls);

                for (var source in video.target.children) {
                  var videoSource = video.target.children[source];
                  if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                    videoSource.src = videoSource.dataset.src;
                  }
                }
  
                video.target.load();
                video.target.classList.remove(cls);
                lazyVideoObserver.unobserve(video.target);
              }
            });
          });
  
          lazyVideos.forEach(function(lazyVideo) {
            lazyVideoObserver.observe(lazyVideo);
          });
        }
      //});

        let divs = document.querySelectorAll(".card_post");
        divs.forEach(function(vid) {
          let vidh = vid.querySelector("#myVideo");

          vidh.addEventListener('loadstart', function () {
            vid.classList.add('loadingDark')
          });
          vidh.addEventListener('loadeddata', function () {
            vid.classList.remove('loadingDark')
          });
        })
      }
    },
    methods: {
      /* eslint no-unused-vars: "off" */
      aboutError(id) {
        console.log("eeee")
        id++;
      },
      emit() {
        this.$emit('loadedEvent')
      }
    }
  }
</script>
  
<style>
.card_post {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  cursor: pointer;
  min-width: 330px;
}

.card_post:hover>* {
  color: var(--logo-red);
  transition: 300ms;
}

/* .title_box {
  position: absolute;
  height: 35px;
  padding: 0 10px;

  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.404);
} */

.image {
  width: 100%;
  min-width: 315px;
  min-height: 260px;
  height: 100%;
  object-fit: cover;
}

#title {
  margin: 10px 0 0 0;
}

#content {
  margin: 5px 0;
}

.image::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 10px;
  bottom: 0;
  left: 0;
  background-color: #931F18;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.image:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@keyframes ldio-y4gm4vs2yr {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.loadingDark::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  border: 5px solid rgb(0, 0, 0);
  border-top-color: transparent;
  border-radius: 50%;
  bottom: 50%;
  left: 50%;
  animation: ldio-y4gm4vs2yr 1s linear infinite;
}
</style>