<template>
  <div id="vc" v-if="vids[0]">

        <Transition appear name="fade">
    <div class="wrapper-video">
      <div id="one" class="v-card container-video-light" v-if="vids[0]">

        <recent-card />

<!-- 
          <video class="card-video" @loadeddata="loadedFirst = true" autoplay muted loop>
            {{ vids[0].acf.video }}
            <source :src="vids[0].acf.video" type="video/mp4">
          </video>
        </Transition>

        <Transition name="fade">
          <div id="text-one" v-if="loadedFirst">
            <p class="sub_heading inter-semi-bold-fuscous-gray-xl">{{ vids[0].title.rendered }}</p>
            <p class="inter-normal-fuscous-gray-m">{{ vids[0].acf.video_description }}</p>
            <a :href="vids[0].acf.link" target="_blank">
              <button-dark-big v-if="$route.path !== '/portfolio'" class="video_button" :content="vids[0].acf.button_text" />
            </a>
          </div>
-->

      </div> 
    </div>

        </Transition> 
    <div class="wrapper_2">
      <div id="two" class="container-video">
        <div class="v-card-reverse" v-if="vids[1]">
          <!-- <Transition name="fade">
            <div v-if="loadedSecond && !mobile">
              <p class="sub_heading inter-semi-bold-alto-xl">{{ vids[1].title.rendered }}</p>
              <p class="inter-normal-alto-m">{{ vids[1].acf.video_description }}</p>
              <a :href="vids[1].acf.link" target="_blank">
                <button-light-big v-if="$route.path !== '/portfolio'" class="video_button" :content="vids[1].acf.button_text" />
              </a>
            </div>
          </Transition>

          <Transition appear name="fade">
            <video class="card-video" @loadeddata="loadedSecond = true" autoplay muted loop>
              <source :src="vids[1].acf.video" type="video/mp4">
            </video>
          </Transition>

          <Transition name="fade">
            <div class="text-one" v-if="loadedSecond && mobile">
              <p class="sub_heading inter-semi-bold-alto-xl">{{ vids[1].title.rendered }}</p>
              <p class="inter-normal-alto-m">{{ vids[1].acf.video_description }}</p>
              <a :href="vids[1].acf.link" target="_blank">
                <button-light-big v-if="$route.path !== '/portfolio'" class="video_button" :content="vids[1].acf.button_text" />
              </a>
            </div>
          </Transition> -->
        </div> 
      </div> 
    </div>
  </div>
</template>
  
<script>
import axios from 'axios'
// import buttonDarkBig from '../buttons/buttonDarkBig.vue';
// import buttonLightBig from '../buttons/buttonLightBig.vue';
import recentCard from './recentCard.vue';

export default {
  name: "videoCard",
  data() {
    return {
      vids: [],
      loadedFirst: true,
      loadedSecond: true,
      mobile: false
    }
  },
  components: {
    // buttonDarkBig,
    // buttonLightBig
    recentCard
  },
  created() {
    this.getData();
    if(window.innerWidth < 480) {
      this.mobile = true;
    }
    addEventListener('resize', function() {
      if(window.innerWidth < 480) {
        this.mobile = true;
      }
    });
  },
  methods: {
    async getData() {
      try {
        let response = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/video");
        this.vids = await response.data;
      } catch (error) {
        console.log(error);
      }
    },
  }
};
</script>
  
<style>
#one {
  opacity: 0%;
  transition: 300ms;
}
#text-one {
  max-width: 400px;
}
#two {
  opacity: 0%;
  transition: 300ms;
}

#vc {
  margin-top: 100px;
  position: relative;
  z-index: 30;
  margin-bottom: 400px;
}



.wrapper_2 {
  /* padding-bottom: 100px; */
  background-color: rgb(15, 15, 15);
}

.container-video-light {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.video_button {
  margin: 40px 10px;
}

.wrapper-video {
  background-color: white;
  z-index: 60;
  transition: 300ms;
}

.v-card {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 30px;
  height: fit-content;
  text-align: left;
  padding-bottom: 60px;
  flex-wrap: wrap;
}

.v-card-reverse {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 120px;
  height: fit-content;
  text-align: left;
  padding-top: 60px;
  padding-bottom: 30px;
  width: fit-content;
  margin-left: auto;
  margin-right: 0;
  flex-wrap: wrap;
}

@media (max-width: 480px) {
  .v-card-reverse {
    gap: 30px;
    padding-top: 0;
  }
}

.card-video {
  width: 43vw;
  height: 36vw;
  min-width: 330px;
  min-height: 250px;
  max-width: 650px;
  max-height: 450px;
  object-fit: cover;
}

.sub_heading {
  align-self: left;
}

.heading {
  align-self: left;
  margin-bottom: 30px;
}
@media (max-width: 480px) {
  #vc {
    margin-top: 0;
    margin-bottom: 0 !important;
  }
  .card-video {
    width: 100% !important;

  }

}
</style>