<template>
    <div class="button-6 inter-medium-alto-m">
        <span>{{content}}</span>
    </div>
</template>

<script>
export default {
    name: "secondaryButton",
    props: ["content"],
}
</script>

<style>
.button-6 {
    box-sizing: border-box;
    background-color: #D9D9D9;
    border: 2px solid #D9D9D9;
    /* Auto layout */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 20px;
    /* margin: 30px 10px; */
    gap: 10px;
    width: fit-content;

    position: relative;
    color: black;
    border-radius: 100px;
    transition: 0.3s;
    cursor: pointer;
}
.button-6:hover {
    background-color: #b8b8b8;
    border: 2px solid #b8b8b8;
    color: black;
}
</style>