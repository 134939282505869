<template>
    <div id="rental-wrapper">
    <rental-card id="rental-card" @isloaded="isLoaded = true" />

    <!-- <Transition appear name="fade">
        <div v-if="isLoaded" style="background: #FFFFFF;">
            <div class="rental-list container">
                <h1 class="inter-semi-bold-fuscous-gray-m">{{ rentalText.title.rendered }}</h1>
                <p class="inter-medium-fuscous-gray-s" v-html="rentalText.acf.description.replaceAll('\r\n', '<br>')"></p>
            </div>
        </div>
    </Transition> -->

    <footer-element-dark v-if="isLoaded" />
    </div>
</template>
    
<script>
import rentalCard from '@/components/cards/rentalCard.vue'
import footerElementDark from '@/components/footerDark.vue'
import axios from 'axios'

export default {
    name: "rentalPage",
    data() {
        return {
            isLoaded: false,
            rentalText: ""
        }
    },
    components: {
        rentalCard,
        footerElementDark
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
            try {
                let response = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/rental");
                this.rentalText = await response.data[0];
            } catch (error) {
                console.log(error);
            }
        }
    },
}
</script>
    
<style>
#rental-card {
    margin-top: 120px;
    padding-bottom: 60px;
}

.container-rental {
    background-color: white;
    padding: 60px;
    margin-top: 60px;
}

.rental-list {
    padding-top: 60px;
    padding-bottom: 180px;
    line-height: 1rem;
}
#rental-wrapper {
    height: fit-content;

    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>