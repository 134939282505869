<template>
    <img class="logo" :src="this.$baseUrl + '/LogoWhite.png'" @click="this.$router.push('/')" />
</template>

<script>
export default {
    name: "LogoMain"
}
</script>

<style>
.logo {
    position: relative;
    height: 60px;
    box-shadow: none;
    padding: 5px;
    cursor: pointer;
}
</style>