<template>
  <div class="nav-wrapper">
    <div class="nav-bar container">
      <logo />

      <img class="menu-button" @click="toggleMenu" :src="this.$baseUrl + '/icons/icon-menu.png'" />
      <div class="main-nav">
        <button-light @click="redirect('/portfolio')" content="Portfolio" />
        <button-light @click="redirect('/blog')" content="Blog" />
        <button-light @click="redirect('/rental')" content="Rental" />
        <button-light @click="redirect('/contact')" content="Contact" />
        <button-light @click="redirect('/about')" content="About" />
      </div>
    </div>

    <div class="menu">
      <div class="menu-items">
        <button-light @click="redirect('/portfolio')" content="Portfolio" />
        <button-light @click="redirect('/blog')" content="Blog" />
        <button-light @click="redirect('/rental')" content="Rental" />
        <button-light @click="redirect('/contact')" content="Contact" />
        <button-light @click="redirect('/about')" content="About" />
      </div>
    </div>
  </div>
</template>
  
<script>
import buttonLight from './buttons/buttonLight.vue'
import Logo from './Logo.vue';

export default {
  name: "MainNav",
  components: {
    buttonLight,
    Logo
  },
  methods: {
    toggleMenu() {
      var visibility = document.querySelector(".menu");

      if (visibility.style.display === "none" || visibility.style.display === "") {
        visibility.style.display = "block";
        document.querySelector("body").style.overflow = "hidden"
      } else {
        visibility.style.display = "none";
        document.querySelector("body").style.overflow = "auto"
      }
    },
    redirect(url) {
      this.$router.push(url);

      var visibility = document.querySelector(".menu");
      visibility.style.display = "none";
      document.querySelector("body").style.overflow = "auto"
    }
  }
};
</script>
  
<style>
.menu {
  width: 100%;
  height: 20rem;
  position: absolute;
  top: 70px;
  background-color: rgb(15, 15, 15);
  display: none;
  z-index: 1;
}

.menu-items {
  position: absolute;
  top: -0px;
  left: calc(50% - 65px);
  
}

.nav-wrapper {
  width: 100%;
  background-color: rgb(15, 15, 15);
}

.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
}

.main-nav {
  display: flex;
}

.menu-button {
  display: none;
  width: 25px;
  height: 25px;
  margin: 20px;
}

@media (max-width: 1080px) {
  .main-nav {
    display: none;
  }
  .menu-button {
    display: block;
  }
}

.contact_button {
  font-weight: 600;
  position: absolute;
  top: -5px;
  left: calc(50% - 65px);
  height: 50%;
  z-index: 900;
}
</style>