<template>
    <div v-if="posts[0]">
        <blog-card-latest id="latest-blog" @isloaded="isLoaded = true" />

        <Transition appear name="fade">
            <div v-if="isLoaded" style="background: #F3F3F3;">
                <div class="blog-list container">
                    <h1 class="inter-semi-bold-fuscous-gray-xl">All posts</h1>
                    <div class="list">
                        <div class="wrapper-blog" v-for="blogpost in posts.slice(1)" :key="blogpost">
                            <post @click="toPost(blogpost.id)" :link="blogpost.acf.image_1" type="image"
                                :title="blogpost.title.rendered" :content="formatedDate(blogpost.acf.date)" />
                        </div>
                    </div>
                </div>
            </div>
        </Transition>

        <footer-element v-if="isLoaded" />
    </div>
</template>
    
<script>
import axios from 'axios'
import blogCardLatest from '@/components/cards/blogCardLatest.vue'
import post from '@/components/cards/post.vue';
import footerElement from '@/components/footer.vue'

export default {
    name: "blogPage",
    data() {
        return {
            posts: [],
            isLoaded: false
        }
    },
    components: {
        blogCardLatest,
        post,
        footerElement
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            try {
                let response = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/blog");
                this.posts = await response.data;
            } catch (error) {
                console.log(error);
            }
        },
        formatedDate(unformatted) {
            var date = new Date(unformatted);

            var options = { year: 'numeric', month: 'long', day: 'numeric' };

            var formatted = date.toLocaleDateString("en-US", options)

            return formatted
        },
        toPost(id) {
            this.$router.push({
                path: '/blogpost',
                query: { id: id }
            })
        }
    }
}
</script>

<style>
#latest-blog {
    background-color: #FFFFFF;
    border-bottom: none;
    z-index: 500;
    padding-bottom: 90px;
    margin-top: 60px;
}

.blog-list {
    margin-top: 0px;
    padding-top: 60px;
    padding-bottom: 120px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
</style>