<template>
    <div class="contact-footer">
        <div class="container nav-bar">
            <div>
                <a href="https://www.facebook.com/simonkrvideoproduction/" target="_blank">
                    <img class="icon" :key="id1" @error="footerErr(id1)" :src="this.$baseUrl + '/icons-dark/icon-facebook-dark.png'">
                </a>
                <!--
                <a href="https://twitter.com/simonkrzic/" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons-dark/icon-twitter-dark.png'">
                </a>
                -->
                <a href="https://vimeo.com/simonkr" target="_blank">
                    <img class="icon" :key="id2" @error="footerErr(id2)" :src="this.$baseUrl + '/icons-dark/icon-vimeo-dark.png'">
                </a>
                <a href="https://www.youtube.com/user/simonkrzic" target="_blank">
                    <img class="icon" :key="id3" @error="footerErr(id3)" :src="this.$baseUrl + '/icons-dark/icon-youtube-dark.png'">
                </a>
                <a href="https://www.instagram.com/simonkrvp/" target="_blank">
                    <img class="icon" :key="id4" @error="footerErr(id4)" :src="this.$baseUrl + '/icons-dark/icon-instagram-dark.png'">
                </a>
                <a href="https://www.tiktok.com/@simonkrvideoproduction?lang=en" target="_blank">
                    <img class="icon" :key="id5" @error="footerErr(id5)" :src="this.$baseUrl + '/icons-dark/icon-tiktok-dark.png'">
                </a>
            </div>
            <div>
                <a href="https://www.gettyimages.com/search/photographer?photographer=simonkr" target="_blank">
                    <img class="icon-medium" :key="id6" @error="footerErr(id6)" :src="this.$baseUrl + '/icons-dark/getty-logo-dark.png'">
                </a>
                <a href="https://www.istockphoto.com/portfolio/simonkr?mediatype=photography" target="_blank">
                    <img class="icon-small" :key="id7" @error="footerErr(id7)" :src="this.$baseUrl + '/icons-dark/istock-logo-dark.png'">
                </a>
            </div>
        </div>
        <div id="img_contain">
            <img class="clients-img" :src="this.$baseUrl + '/clients-2.png'">
        </div>
    </div>
</template>
    
<script>
export default {
    name: "footerShortElement",
    data() {
        return {
            id1: 0,
            id2: 0,
            id3: 0,
            id4: 0,
            id5: 0,
            id6: 0,
            id7: 0
        }
    },
    methods: {
        /* eslint no-unused-vars: "off" */
        footerErr(iId) {
            iId++;
        }
    }
};
</script>
    
<style>
.contact-footer {
    position: relative;
    background-color: white;
    width: 100%;
}

.clients-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-width: 1000px;
    overflow: hidden;
}

#img_contain {
    position: -webkit-sticky;
    bottom: 0;
    overflow: hidden;
    background-color: black;
}
</style>