<template>
    <div v-if="(posts[0] && allTags[0])"> // || subCategories[0]">
        <Transition appear name="fade">
            <div style="background: #F3F3F3;">
                <div class="blog-list container category">
                        <!-- title for categories -->
                        <!-- <h1 v-if="subCategories[0]" class="inter-semi-bold-fuscous-gray-xl">{{this.$route.query.name}} categories</h1> -->
                        <!-- title for posts -->
                        <h1 v-if="posts[0]" style="margin-bottom: 30px;" class="inter-semi-bold-fuscous-gray-xl">{{this.$route.query.name}} categories</h1>

                        <!-- tags -->
                        <div v-if="posts[0]"  style="margin-bottom: -10px;" id="website-buttons">
                            <div v-for="tag in allTags" :key="tag.id" v-show="tags.includes(tag.id)">
                                <button-dark-big v-if="!tag.active" @click="filter(tag)" :content="tag.name" />
                                <button-dark-big v-else @click="filter(tag)" :content="tag.name" style="background-color: #F3F3F3; color: black;"/>
                            </div>
                        </div>
                    <div class="list">
                            <!-- posts -->
                            <div class="wrapper-blog" v-for="post in posts.slice(0, numberOfPosts)" :key="post.id" v-show="activeTags.every(tag => post.tags.includes(tag))">
                                <a v-if="post.acf.image_1 != ''" :href="post.acf.getty__istock_link" target="_blank">
                                    <post  :link="post.acf.image_1" type="image" :title="post.title.rendered"
                                    :content="post.acf.short_description" />
                                </a>
                                <a v-else :href="post.acf.getty__istock_link" target="_blank">
                                    <post :link="post.acf.video" type="video" :title="post.title.rendered"
                                    :content="post.acf.short_description" />
                                </a>
                            </div>
                            <!-- subcategories -->

                            <!-- 
                            <div class="wrapper-blog" v-for="subCategorie in subCategories.slice(0, numberOfPosts)" :key="subCategorie.id">
                                <Transition appear name="fade">
                                    <post @click="toCategorie(subCategorie.id, subCategorie.name)" v-if="subCategorie.acf.category_image != ''" :link="subCategorie.acf.category_image" type="image" :title="subCategorie.name" />
                                    <post v-else @click="toCategorie(subCategorie.id, subCategorie.name)" :link="subCategorie.acf.category_video" type="video" :title="subCategorie.name" />
                                </Transition>
                            </div>
                             -->
                            <button-dark-big class="show_more" v-if="numberOfPosts<posts.length" @click="numberOfPosts*=2" content="Show more" />
                    </div>
                </div>
            </div>
        </Transition>

        <footer-element/>
    </div>
</template>
    
<script>
import axios from 'axios'
import post from '@/components/cards/post.vue';
import footerElement from '@/components/footer.vue'
import buttonDarkBig from '@/components/buttons/buttonDarkBig.vue';

export default {
    name: "categoriePage",
    props: ['name'],
    data() {
        return {
            posts: [],
            //subCategories: [],
            allTags: [],
            tags: [],
            activeTags: [],
            numberOfPosts: 9
        }
    },
    components: {
        post,
        footerElement,
        buttonDarkBig
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            try {
                //get subcategories
                // let response = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/categories?acf_format=standard&parent=" + this.$route.query.id);
                // this.subCategories = await response.data;
                
                // //show posts and retrieve tags if no subcategories
                // if(this.subCategories.length == 0) {
                    let posts = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/posts?categories=" + this.$route.query.id);
                    this.posts = posts.data;
                    
                    let tags = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/tags")
                    this.allTags = tags.data

                    for(var i = 0; i < this.posts.length; i++) {
                        this.tags = this.tags.concat(this.posts[i].tags);
                    }
                    this.tags = [... new Set(this.tags)];
                // } 
            } catch (error) {
                console.log(error);
            }
        },
        formatedDate(unformatted) {
            var date = new Date(unformatted);

            var options = { year: 'numeric', month: 'long', day: 'numeric' };

            var formatted = date.toLocaleDateString("en-US", options)

            return formatted
        },
        toCategorie(id, name) {
            this.$router.push({
                path: '/categorie',
                query: { 
                    id: id,
                    name: name
                }
            })
        },
        filter(tag) {
            if(tag.active) {
                tag.active = false
            } else {
                tag.active = true
            }
            this.activeTags = [];
            for(var i = 0; i < this.allTags.length; i++) {
                if(this.allTags[i].active) {
                    this.activeTags.push(this.allTags[i].id);
                }
            }
        }
    }
}
</script>

<style>
.category {
    margin-top: 30px;
}
.show_more {
    margin: 50px auto;
}
</style>