<template>
    <div id="about-wrapper">
    <Transition appear name="fade">
        <div id="container-about" class="container" v-if="aboutText[0]">
            <div class="about">
                <br>
                <p class="inter-semi-bold-alto-l">{{aboutText[0].title.rendered}}</p>
                <br>
                <p class="inter-medium-alto-s" v-html="aboutText[0].acf.about.replaceAll('\r\n', '<br>')"></p>
            </div>
        </div>

    </Transition>
    <Transition appear name="fade">
        <div id="crew-section" v-if="loaded">
            <!-- <div class="container">
                <p class="inter-semi-bold-fuscous-gray-l">Crew</p>

                <div v-for="crewMember in crew" class="crew-block"  :key="crewMember.acf.name">
                    <div class="crew-desc" >
                        <p class="inter-medium-fuscous-gray-m" v-html="crewMember.acf.name"></p>
                        <p class="inter-medium-fuscous-gray-s" v-html="crewMember.acf.role"></p>
                    </div>
                    <img  :src="crewMember.acf.image" width="330" >
                </div>
            </div> -->
            <div class="container">

                <p class="inter-semi-bold-fuscous-gray-l">Crew</p>
                <div class="crew-list">
                    <div class="wrapper-crew" v-for="crewMember in crew" :key="crewMember.name">
                        <post type="image"
                            :title="crewMember.acf.name" :content="crewMember.acf.role" :link="crewMember.acf.image" id="0"/>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
    <Transition appear name="fade">
        <footerShort v-if="loaded"/>
    </Transition>
    </div>
</template>
    
<script>
import footerShort from '@/components/footerShort.vue';
import axios from 'axios';
import post from '@/components/cards/post.vue'

export default {
    name: "aboutPage",
    data() {
        return {
            aboutText: "",
            crew: [],
            loaded: false
        }
    },
    components: {
        footerShort,
        post
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
            try {
                let response = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/about");
                this.aboutText = await response.data;

                let crewsponse = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/crew?per_page=30");
                this.crew = await crewsponse.data;

                this.loaded = true;
            } catch (error) {
                console.log(error);
            }
        }
    },
}
</script>
    
<style>
#container-about {
    padding-top: 120px;
}
#crew-section {
    padding: 30px 0 90px 0;
    background-color: white;
}
.crew-desc {
    margin-right: 90px;
}
.crew-block {
    margin: 30px 90px;
    display: flex; 
    flex-direction: flex-start;
    flex-wrap: wrap;
}

.about {
    width: 50vw;
    min-width: 330px;
    margin-left: 0;
    margin-right: auto;
    padding-bottom: 120px;
}
.crew-list {
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}
#about-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>