<template>
  <Transition appear name="fade">
    <div class="card_blog" id="bc">
      <div id="bc-cont" class="content">

        <div class="wrapper-portfolio-light">

          <div class="card container" v-if="this.imgs[0]">
              <p class="heading inter-medium-alto-xl">Blog</p>

            <div class="latest_project">
              
                <flickity v-if="imgs" ref="flickity"  @init="onInit()" :options="flickityOptions">
                  <div v-for="(post, index) in imgs.slice(0, 5)" :class="'carousel-cell' + ' cell' + index" :key="post.title" >
                    <div style="height: 100%;" @click="this.$router.push({
                path: '/blogpost',
                query: { id: imgs[index].id }
              })">
                      <img class="img11"  v-if="post.acf.image_1 != ''" :key="post.id" @error="showErr(post)" @mouseover="setSelected(index)" :src="post.acf.image_1" />
                      <video v-else class="img11 lazyBlog" @mouseover="setSelected(index)" autoplay muted loop id="myVideo">
                        <source :data-src="post.acf.video" type="video/mp4">
                      </video> 
                    </div>
                  </div>
                </flickity>
              
            </div>

            <div>
              <p class="inter-medium-alto-m">{{ imgs[index].title.rendered}}</p>
              <p class="inter-normal-alto-m">{{ imgs[index].acf.short_description }}</p>
            </div>

            <div class="portfolio_link">
              <div id="latest-post-buttons">
              <secondary-button-dark class="read-more" @click="this.$router.push({
                path: '/blogpost',
                query: { id: imgs[index].id }
              })" content="Read more" />
              <secondary-button-dark class="read-more-mobile" @click="this.$router.push({
                path: '/blogpost',
                query: { id: imgs[index].id }
              })" content="Read more" />
              </div>
              
            </div>
            
            <button-light-big v-if="$route.path !== '/blog'" @click="$router.push('/blog')" class="blog_button" content="Blog" />
          </div>

        </div>

        
      </div>


    </div>
  </Transition>
</template>
     
<script>
import axios from 'axios'
import flickity from 'vue-flickity'
import buttonLightBig from '../buttons/buttonLightBig.vue';
import secondaryButtonDark from '../buttons/secondaryButtonDark.vue';

export default {

  name: "recentCard",
  data() {
    return {
      imgs: [],
      index: 0,
      selector: ".first",
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: false,
        contain: true,
      },
      componentKey: 0,
      timeout: 0
    }
  },
  components: {
    flickity,
    buttonLightBig,
    secondaryButtonDark
  },
  created() {
    this.getData();

  },
  methods: {
    showErr(post) {
      post.id++;
    },
    async getData() {
      try {
        let response = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/blog");

        this.imgs = await response.data;
        
        this.$emit('isloaded')
        this.lazyLoad();
      } catch (error) {
        console.log(error);
      }
    },
    lazyLoad() {
      var lazyVideos = [].slice.call(document.querySelectorAll("video.lazyBlog"));
        if ("IntersectionObserver" in window) {
          var lazyVideoObserver = new IntersectionObserver(function(entries) {
            entries.forEach(function(video) {
              if (video.isIntersecting) {
                console.log("loadedBlog");

                for (var source in video.target.children) {
                  var videoSource = video.target.children[source];
                  if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                    videoSource.src = videoSource.dataset.src;
                  }
                }
  
                video.target.load();
                video.target.classList.remove("lazy");
                lazyVideoObserver.unobserve(video.target);
              }
            });
          });
  
          lazyVideos.forEach(function(lazyVideo) {
            lazyVideoObserver.observe(lazyVideo);
          });
        }
    },
    next() {
      if (this.index < 4) {
        this.index++
        this.setSelected(this.index)
      }
      this.$refs.flickity.next();
    },

    previous() {
      if (this.index != 0) {
        this.index--
        this.setSelected(this.index)
      }
      this.$refs.flickity.previous();
    },
    setSelected(i) {
      // if (document.querySelector(".is-selected") != null) {
      //   document.querySelector(".is-selected").classList.remove("is-selected");
      // }
      this.index = i;
      this.selector = ".cell" + i;
      // document.querySelector(this.selector).classList.add("is-selected")
    },
    onInit(){ this.$refs.flickity.on('change', (index) => { this.setSelected(index) }) }
  }
};

</script>
    
<style>
.video_button_big {
  position: absolute;
  left: calc(50% - 75px);
  margin-top: -25px;
  top: 0;
}

.blog_button {
  position: relative;
  margin-left: auto;
  margin-right: 10px;
}

#bc {
  position: relative;
  z-index: 300;
}

#bc-cont {
  transition: 300ms;
  opacity: 100%;
}

.teaser {
  margin-bottom: 60px;
}

.titel {
  margin: 0;
}

.card_blog {
  background-color: rgb(15, 15, 15);
  box-sizing: border-box;
  width: 100%;
  position: relative;
  height: fit-content;
  text-align: left;
  padding: 30px 0px 0px 0;
  /* border-bottom: 50px solid #931F18; */
  z-index: -1;


}
.read-more-mobile {
  display: none;
}
.blog_img {
  width: 35vw;
  height: 25vw;
  min-height: 250px;
  min-width: 330px;
  max-height: 450px;
  max-width: 550px;
  object-fit: cover;
  box-shadow: -2px 4px 60px 2px rgba(0, 0, 0, 0.25);
}

@media (max-width: 480px) {
  .card_blog {
    padding: 0px 0 30px 0;
    margin-top: 0;
  }
  .read-more {
    display: none;
  }
  .read-more-mobile {
    display: block;
  } 
  .blog_button {
    position: relative;
    margin-left: 10px;
  }
  .video_button_big {
    display: none;
  }
  .blog_img {
    width: 100%;
  }
}


.content {
  position: relative;
  gap: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: fit-content;
  flex-wrap: wrap;
}




.post-content {
  overflow-wrap: break-word;
  width: 45vw;
  min-width: 330px;
  max-width: 400px;
}


#latest-post-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  height: fit-content;
}

#pc {
  transition: 300ms;
}

.carousel-cell {
  width: 43vw;
  height: 36vw;
  margin-right: 20px;
  min-width: 330px;
  min-height: 250px;
  max-width: 650px;
  max-height: 500px;
}


.latest_project {
  padding-bottom: 10px;
}

.img11 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio_link {
  width: 100%;
}

/* .carousel_buttons {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  bottom: 90px;
} */

@media (max-width: 480px) {
  .carousel_buttons {
    display: none;
  }
}

.portfolio_button {
  margin: auto;
}

.sub_heading {
  align-self: left;
}

.heading {
  align-self: left;
}


/* .carousel-cell::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 10px;
  bottom: 0;
  left: 0;
  background-color: #931e189a;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
} */

.wrapper-portfolio-light {
  width: 100%;
}

.portfolio_link {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
</style>