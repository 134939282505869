<template>
    <div class="card_blog_post" v-if="post && isLoaded">
        <Transition appear name="fade">
            <div class="blog-content">
                <img class="img2" :src="post.acf.image_1" />
                <p class="inter-medium-fuscous-gray-m">{{ formatedDate(post.acf.date) }}</p>

                <div v-if="post" class="inter-medium-fuscous-gray-xxl">
                    <p>{{ post.title.rendered }}</p>
                </div>
                <div v-if="post" class="inter-normal-fuscous-gray-m">
                    <p id="content-text" v-html="post.acf.content.replaceAll('\r\n', '<br>')"></p>
                </div>
            </div>
        </Transition>
    </div>

    <!-- <div class="container">
        <p class="inter-medium-alto-m">Gallery</p>
    </div> -->
    <Transition appear name="fade">
        <footerElement v-if="isLoaded"/>
    </Transition>
</template>
    
<script>
import axios from 'axios';
import footerElement from '@/components/footer.vue';

export default {
    name: "blogPostPage",
    data() {
        return {
            post: {},
            isLoaded: false
        }
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
            try {
                let response = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/blog?include[]=" + this.$route.query.id);
                this.post = await response.data[0];
                this.isLoaded = true;
            } catch (error) {
                console.log(error);
            }
        },
        formatedDate(unformatted) {
            var date = new Date(unformatted);
            var options = { year: 'numeric', month: 'long', day: 'numeric' };

            var formatted = date.toLocaleDateString("en-US", options)

            return formatted
        },
    },
    components: {
        footerElement
    }
};
</script>
    
<style>
.card_blog_post {
    background-color: #FFFFFF;
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: left;
}

.blog-content {
    text-align: left;
    width: 50vw;
    padding-bottom: 60px;
    min-width: 330px;
    word-wrap: break-word;
}

.img2 {
    width: 100%;
}
</style>