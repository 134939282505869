<template>
    <div class="wrapper-portfolio-light">
        

      <div class="card-light container" v-if="this.imgs[0]">
        <p class="heading inter-medium-fuscous-gray-xl">Recent projects</p>

        <div class="latest_project">
            <flickity v-if="imgs[0]" ref="flickity"  @init="onInit()" :options="flickityOptions">
              <div v-for="(post, index) in imgs.slice(0, 5)" :class="'carousel-cell' + ' cell' + index" :key="post.title" >
                <a :href="post.acf.getty__istock_link" target="_blank">
                  <img class="img11"  v-if="post.acf.image_1 != ''" @mouseover="setSelected(index)" :src="post.acf.image_1" />
                  <video v-else class="img11 lazy" @mouseover="setSelected(index)" autoplay muted loop id="myVideo">
                    <source :data-src="post.acf.video" type="video/mp4">
                  </video> 
                </a>
              </div>
            </flickity>
        </div>

        <div>
          <p class="inter-medium-fuscous-gray-m">{{ imgs[index].title.rendered}}</p>
          <p class="inter-normal-fuscous-gray-m">{{ imgs[index].acf.short_description }}</p>
        </div>

        <div class="portfolio_link">
          <div id="latest-post-buttons">
          <a :href="imgs[index].acf.getty__istock_link" target="_blank">
            <button-dark-big style="display: inline-block" content="See more" />
          </a>
          <div v-for="categorie in displayCategories" :key="categorie.id">
            <secondaryButton class="categorie" v-if="categorie.acf.getty_link == ''" v-show="imgs[index].acf.category.includes(categorie.id)" @click="toCategorie(categorie.id, categorie.name)" :content="categorie.name" />
            <a v-else :href="categorie.acf.getty_link" target="_blank">
              <secondaryButton class="categorie" v-show="imgs[index].acf.category.includes(categorie.id)" :content="categorie.name" />
            </a>
          </div>
          </div>
          
        </div>
      </div>
      
    
    </div>

</template>
  
<script>
import axios from 'axios'
import flickity from 'vue-flickity'

import buttonDarkBig from '../buttons/buttonDarkBig.vue';
import secondaryButton from '../buttons/secondaryButton.vue';

export default {

  name: "recentCard",
  data() {
    return {
      imgs: [],
      index: 0,
      selector: ".first",
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: false,
        contain: true,
      },
      displayCategories: []
    }
  },
  components: {
    flickity,

    buttonDarkBig,
    secondaryButton
  },
  created() {
    this.getData();

  },
  methods: {
    async getData() {
      try {
        let response = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/recent");
        let differentCategories = []

        this.imgs = await response.data;
        for(var i = 0; i < 5; i++) {
          differentCategories = differentCategories.concat(this.imgs[i].acf.category);
        }
        differentCategories = [... new Set(differentCategories)];

        let categories = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/categories?include=" + differentCategories.toString());
        this.displayCategories = await categories.data;

        this.$emit('isloaded')
        this.lazyLoad();
      } catch (error) {
        console.log(error);
      }
    },
    lazyLoad() {
      var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));
        if ("IntersectionObserver" in window) {
          var lazyVideoObserver = new IntersectionObserver(function(entries) {
            entries.forEach(function(video) {
              if (video.isIntersecting) {
                console.log("loadedRecent");

                for (var source in video.target.children) {
                  var videoSource = video.target.children[source];
                  if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                    videoSource.src = videoSource.dataset.src;
                  }
                }
  
                video.target.load();
                video.target.classList.remove("lazy");
                lazyVideoObserver.unobserve(video.target);
              }
            });
          });
  
          lazyVideos.forEach(function(lazyVideo) {
            lazyVideoObserver.observe(lazyVideo);
          });
        }

        let divs = document.querySelectorAll(".carousel-cell");
        divs.forEach(function(vid) {
          let vidh = vid.querySelector("#myVideo");

          vidh.addEventListener('loadstart', function () {
            console.log("loading...")
            vid.classList.add('loadingDarkRecent')
          });
          vidh.addEventListener('loadeddata', function () {
            console.log("playing...")
            vid.classList.remove('loadingDarkRecent')
          });
        })
    },
    next() {
      if (this.index < 4) {
        this.index++
        this.setSelected(this.index)
      }
      this.$refs.flickity.next();
    },

    previous() {
      if (this.index != 0) {
        this.index--
        this.setSelected(this.index)
      }
      this.$refs.flickity.previous();
    },
    setSelected(i) {
    //   if (document.querySelector(".is-selected") != null) {
    //     document.querySelector(".is-selected").classList.remove("is-selected");
    //   }
      this.index = i;
      this.selector = ".cell" + i;
    //   document.querySelector(this.selector).classList.add("is-selected")
    },
    toCategorie(id, name) {
      this.$router.push({
          path: '/categorie',
          query: { 
            id: id,
            name: name
          }
      })
    },
    onInit(){ this.$refs.flickity.on('change', (index) => { this.setSelected(index) }) }
  }
};
</script>
  
<style>
.flickity-prev-next-button .arrow{
  fill: white;
}
.flickity-prev-next-button {
  width: 40px;
  height: 40px;
  background: transparent;
}

.flickity-button:hover {
    background: transparent;
}
#latest-post-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  height: fit-content;
}

#pc {
  transition: 300ms;
}

.carousel-cell {
  width: 43vw;
  height: 36vw;
  margin-right: 20px;
  min-width: 330px;
  min-height: 250px;
  max-width: 650px;
  max-height: 500px;
}

.card-light {
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
  text-align: left;
  padding-top: 60px;
}

.latest_project {
  padding-bottom: 10px;
}

.img11 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio_link {
  width: 100%;
}

/* .carousel_buttons {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  bottom: 90px;
} */

@media (max-width: 480px) {
  .carousel_buttons {
    display: none;
  }
}

.portfolio_button {
  margin: auto;
}

.sub_heading {
  align-self: left;
}

.heading {
  align-self: left;
  margin-bottom: 30px;
}


/* .carousel-cell::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 10px;
  bottom: 0;
  left: 0;
  background-color: #931e189a;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
} */




.wrapper-portfolio-light {
  width: 100%;
}

.portfolio_link {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

@keyframes ldio-y4gm4vs2yr {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.loadingDarkRecent::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  border: 5px solid rgb(0, 0, 0);
  border-top-color: transparent;
  border-radius: 50%;
  bottom: calc(50% - 40px);
  left: 50%;
  animation: ldio-y4gm4vs2yr 1s linear infinite;
}
</style>