<template>
  <div class="portfolio" v-if="categories[0]">
    <portfolioCard id="portfolio-card" @isloaded="isLoaded = true" />

    <Transition name="fade">
      <div v-if="isLoaded" style="background: #F3F3F3;">
        <div class="portfolio-list container">
          <div class="title-bar">
            <h1 class="inter-semi-bold-fuscous-gray-xl">Categories</h1>
            <div id="website-buttons">
              <a href="https://www.gettyimages.com/search/2/image?family=creative&phrase=simonkr" target="_blank">
                <button-dark-big v-if="$route.path !== '/blog'"
                content="GettyImages" />
              </a>
              <a href="https://www.istockphoto.com/search/2/image?family=creative&phrase=simonkr" target="_blank">
              <button-dark-big v-if="$route.path !== '/blog'"
                content="iStock" />
              </a>
            </div>
          </div>
          <div class="list" v-if="categories[0]">
            <div class="forr" v-for="(categorie, index) in categories.slice(0, numberOfCategories)" :key="categorie.name" >
            <div class="wrapper-portfolio-post"  v-if="index <= load">
                <Transition appear name="fade">
                  <post @click="toPost(categorie.id, categorie.name)" v-if="categorie.acf.category_image != ''" :link="categorie.acf.category_image" type="image" :title="categorie.name" />
                  <post v-else-if="index <= load" @loadedEvent="unlock()" @click="toPost(categorie.id, categorie.name)" :link="categorie.acf.category_video" :id="categorie.id" type="video" :title="categorie.name" />
                </Transition>
            </div>
            </div>

            <button-dark-big class="show_more" v-if="numberOfCategories<categories.length" @click="numberOfCategories*=2" content="Show more" />
          </div>
        </div>
      </div>
    </Transition>

    <footer-element v-if="isLoaded" />
  </div>
</template>
    
<script>
import axios from 'axios'
import portfolioCard from '../components/cards/portfolioCard.vue'
import post from '../components/cards/post.vue'
import footerElement from '../components/footer.vue'
import buttonDarkBig from '@/components/buttons/buttonDarkBig.vue'

export default {
  name: "portfolioPage",
  data() {
    return {
      categories: [],
      isLoaded: false,
      load: 0,
      numberOfCategories: 8
    }
  },
  components: {
    portfolioCard,
    post,
    footerElement,
    buttonDarkBig
  },
  created() {
    this.getData();
  },

  methods: {
    unlock() {
      this.load++;
    },
    async getData() {
      try {
        let response = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/categories?acf_format=standard&parent=0");
        this.categories = await response.data;
      } catch (error) {
        console.log(error);
      }
    },
    toPost(id, name) {
      this.$router.push({
          path: '/categorie',
          query: { 
            id: id,
            name: name
          }
      })
    }
  },
};
</script>
    
<style>
.forr {
  width: 48.5%;
  height: fit-content;
}
.title-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
#website-buttons {
  display: flex;
  flex-direction: row;
}
.list {
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  background-color: #F3F3F3;
}

#portfolio-card {
  padding-top: 60px;
}

.portfolio-list {
  padding-top: 60px;
  padding-bottom: 180px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>