<template>
    <div class="button-1 inter-medium-alto-m">
        <span v-html="content"></span>
    </div>
</template>

<script>
export default {
    name: "buttonLightBig",
    props: ["content"]
}
</script>

<style>
.button-1 {
    box-sizing: border-box;
    background-color: white;
    /* Auto layout */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 20px;
    gap: 10px;
    width: fit-content;
    position: relative;
    border: 2px solid white;
    color: black;
    border-radius: 100px;
    transition: 0.3s;
    cursor: pointer;
}
.button-1:hover {
    color: white;
    background-color: transparent;
    border: 2px solid white;
}
</style>