<template>
  <Transition appear name="fade">
    <div id="rc" v-if="rentalItems[0]">
      <div class="container-rental-card" v-if="rentalItems">
        <div class="rental_card">
          <div class="title-button">
            <p class="rental-subheading sub_heading inter-semi-bold-fuscous-gray-xl">Rent our Equipment</p>
            <a id="rental-button" :href="rentalItems[1].acf.link" target="_blank">
              <button-dark-big content="Rent now" />
            </a>
          </div>

          <div class="wrp">
              <div class="rental">
                <img class="img12" :src="rentalItems[1].acf.image" />

                <div class="text">
                  <h1 class="inter-bold-fuscous-gray-l">{{ rentalItems[1].title.rendered }}</h1>
                  <br>
                  <p class="inter-medium-fuscous-gray-m">{{ rentalItems[1].acf.description }}</p>
                </div>
              </div>
          </div>
          <a id="rental-button-mobile" :href="rentalItems[1].acf.link" target="_blank">
              <button-dark-big content="Rent now" />
          </a>
        </div>
      </div>
    </div>
  </Transition>
</template>
  
<script>
import axios from 'axios'
import buttonDarkBig from '../buttons/buttonDarkBig.vue'

export default {
  name: "rentalCard",
  data() {
    return {
      rentalItems: [],
      index: 0,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        contain: true,
      }
    }
  },
  components: {
    buttonDarkBig,
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        let response = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/rental");
        this.rentalItems = await response.data;
        this.$emit('isloaded')
      } catch (error) {
        console.log(error);
      }
    },
    next() {
      if (this.index != this.rentalItems.length - 3) {
        this.index++
      }
      this.$refs.flickity.next();
    },

    previous() {
      if (this.index != 0) {
        this.index--
      }
      this.$refs.flickity.previous();
    }
  },
};
</script>
  
<style>
.title-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text {
  padding: 20px 0px;
  max-width: 500px;
}
#rental-button-mobile {
  display: none;
} 


.container-rental-card {
  width: 94%;
  max-width: 1470px;
  margin-left: auto;
  margin-right: auto;
}

#rc {
  margin-top: 0px;
  padding-top: 60px;
  background-color: white;
  z-index: 400;
  position: relative;

  padding-bottom: 120px;
}

.rental_card {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.rental {
  max-width: 1470px;
  width: 75vw;
  height: fit-content;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  box-sizing: border-box;
  border-bottom: none;
  margin-right: 60px;
  min-width: 330px;
  min-height: 250px;
  flex-wrap: wrap;

  gap: 30px;
}

.rental::after {
  background-color: transparent;
}

.img12 {
  object-fit: cover;
  width: 35vw;
  height: 25vw;
  min-height: 250px;
  min-width: 330px;
  max-height: 450px;
  max-width: 550px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.rental_link {
  margin-right: 0;
  margin-left: auto;
  width: fit-content;
}

@media (max-width: 480px) {
  #rental-button {
    display: none;
  } 
  #rental-button-mobile {
    display: block;
  } 

  .text {
    padding-top: 0px;
  }
  .rental, .img12 {
    width: 100%;
  }
}
</style>