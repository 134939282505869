<template><div class="button-2 sticky"><span>{{ content }}</span></div></template>

<script>
export default {
  name: "buttonLight",
  props: ["content"],
}
</script>

<style>
.button-2 {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  letter-spacing: 0;
  line-height: normal;
  width: fit-content;
  position: relative;
  cursor: pointer;
  margin: 10px 0;

  color: white;
    font-family: var(--font-family-inter);
    font-size: var(--font-size-m);
    font-weight: 500;
    font-style: normal;
}

.button-2::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 8px;
  bottom: -10px;
  left: 0;
  background-color: #931F18;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.button-2:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
</style>