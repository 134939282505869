<template>
    <div id="contact-wrapper">
    <Transition appear name="fade">
        <div class="contact container" v-if="contactText[0]">
            <div id="static-contact">
                <p class="inter-semi-bold-alto-l">{{contactText[0].title.rendered}}</p>
                <br>
                <p class="inter-medium-alto-s" v-html="contactText[0].acf.contact.replaceAll('\r\n', '<br>')"></p>
            </div> 

            <form @submit="checkForm" id="contact-form">
                <p class="inter-semi-bold-alto-l">Send us an Email</p>
                <div>
                    <label for="name">Name</label>
                    <input class="input"
                    id="name"
                    v-model="contactName"
                    type="text"
                    name="name"
                    placeholder="Name"
                    >
                </div>
                <div>
                    <label for="age">Email</label>
                    <input class="input"
                    id="age"
                    v-model="contactEmail"
                    type="text"
                    name="email"
                    min="0"
                    placeholder="Email"
                    >
                </div>
                <div>
                    <label for="age">Message</label>
                    <textarea class="input textarea"
                    id="age"
                    v-model="contactMessage"
                    type="text"
                    name="email"
                    min="0"
                    placeholder="Your message"
                    />
                </div>
                <div v-if="message != ''" class="inter-medium-alto-s" >
                    <p>{{ message }}</p>
                </div> 
                <div v-if="errors.length" class="inter-medium-alto-s" style="color: var(--logo-red)">
                    <p>{{ errors.toString() + ' required.'}}</p>
                </div>
                <div>
                    <input class="submit inter-medium-alto-s"
                    type="submit"
                    value="Send Email"
                    >
                </div>

            </form>
            <div />  
        </div>

    </Transition>
    <Transition appear name="fade">
        <footerShort id="foot" v-if="loaded"/>
    </Transition>
</div>
</template>
    
<script>
import footerShort from '@/components/footerShort.vue';
import axios from 'axios';

export default {
    name: "contactPage",
    data() {
        return {
            contactText: "",
            contactName: "",
            contactEmail: "",
            contactMessage: "",
            errors: [],
            loaded: false,
            message: ""
        }
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
            try {
                let response = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/contact");
                this.contactText = await response.data;
                this.loaded = true;
            } catch (error) {
                console.log(error);
            }
        },
        sendEmail () {
            const formData = new FormData()
            formData.append('contact_name', this.contactName)
            formData.append('contact_email', this.contactEmail)
            formData.append('contact_message', this.contactMessage)
            axios.post(this.$baseUrl + '/wp/wp-json/contact/v1/send', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.message = res.data.message
                })
                .catch((err) => {
                    this.errors.push(err.response)
                })
        },
        checkForm: function (e) {
            if (this.name && this.age) {
                return true;
            }

            this.errors = [];

            if (!this.contactName) {
                this.errors.push(' Name');
            }
            if (!this.contactEmail) {
                this.errors.push(' Email');
            }
            if(!this.contactMessage) {
                this.errors.push(' Message');
            }

            e.preventDefault();

            if(this.contactName && this.contactEmail && this.contactMessage) {
                this.sendEmail()
            }
        }
    },
    components: {
        footerShort
    }
}
</script>
    
<style>
.contact {
    margin-top: 130px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 120px;
}
#static-contact {
    margin-bottom: 30px;
}
#contact-form {
    width: 30vw;
    min-width: 300px;
}
.input {
    background: transparent;
    color: white;
    border: 2px solid white;
    border-radius: 100px;
    padding: 10px 15px;
    width: 100%;
}
.textarea {
    height: 100px;
    border-radius: 20px;
    resize: none;
}
.submit {
    margin-top: 30px;
    color: black;
    border: 2px solid white;
    border-radius: 100px;
    padding: 5px 20px;
    background-color: white;
    transition: 300ms;
}
.submit:hover {
    margin-top: 30px;
    color: white;
    border-radius: 100px;
    padding: 5px 20px;
    background-color: transparent;
    transition: 300ms;
}
#contact-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

</style>