<template>
  <main-nav id="navbar" />
  <router-view :key="$route.fullPath"/>
</template>

<script>
import axios from 'axios'
import MainNav from './components/MainNav.vue'

export default {
  name: 'App',

  data() {
    return {
      bgs: [],
      title: "SIMONKR STOCK FOOTAGE COMPANY"
    }
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        let response = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/media");
        this.bgs = await response.data;
      } catch (error) {
        console.log(error);
      }
    }
  },
  components: {
    MainNav
  }
}
</script>
<style>
#navbar {
  z-index: 900;
  position: fixed;
  top: 0;
  margin: 0 auto;
}

</style>