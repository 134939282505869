<template>
    <div class="button-8 inter-medium-alto-m">
        <span v-html="content"></span>
    </div>
</template>

<script>
export default {
    name: "secondaryButtonDark",
    props: ["content"],
}
</script>

<style>
.button-8 {
    box-sizing: border-box;
    border: 2px solid #FFFFFF;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 20px;
    gap: 10px;
    width: fit-content;
    color: white;

    position: relative;
    border-radius: 100px;
    transition: 0.3s;
    cursor: pointer;
}
.button-8:hover {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    color: black;
}
</style>