<template>
  <Transition appear name="fade">
    <div class="card_blog_latest" v-if="latestPost[0]" id="bc">
      <div id="bc-cont" class="content container">
        <img class="blog_img" :src="latestPost[0].acf.image_1" />

        <div class="post-content">
          <p class="teaser inter-semi-bold-fuscous-gray-xl">Latest from blog</p>
          <p class="inter-semi-bold-fuscous-gray-xl">{{ latestPost[0].title.rendered }}</p>
          <p class="inter-normal-fuscous-gray-m">{{ latestPost[0].acf.short_description }}</p>

          <secondary-button class="read-more-latest" @click="this.$router.push({
            path: '/blogpost',
            query: { id: latestPost[0].id }
          })" content="Read more" />
        </div>
      </div>
    </div>
  </Transition>
</template>
     
<script>
import axios from 'axios'
import secondaryButton from '../buttons/secondaryButton.vue'

export default {
  name: "blogCardLatest",
  data() {
    return {
      latestPost: []
    }
  },
  components: {
    secondaryButton
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        let response = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/blog");
        this.latestPost = await response.data;
        this.$emit('isloaded')
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>
    
<style>
.read-more-latest{
    margin-top: 60px;
}
.video_button_big {
  position: absolute;
  left: calc(50% - 75px);
  margin-top: -25px;
  top: 0;
}

#bc {
  position: relative;
  z-index: 300;
}

#bc-cont {
  transition: 300ms;
  opacity: 100%;
}

.teaser {
  margin-bottom: 60px;
}

.titel {
  margin: 0;
}

.card_blog_latest {
  background-color: #F3F3F3;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  height: fit-content;
  text-align: left;
  padding: 90px 0px 180px 0;
  /* border-bottom: 50px solid #931F18; */
  z-index: -1;
  margin-top: 90px;

}

.blog_img {
  width: 35vw;
  height: 25vw;
  min-height: 250px;
  min-width: 330px;
  max-height: 450px;
  max-width: 550px;
  object-fit: cover;
  box-shadow: -2px 4px 60px 2px rgba(0, 0, 0, 0.25);
}

@media (max-width: 480px) {
  .card_blog-latest {
    padding: 60px 0 30px 0;
  }
  
   
  .blog_button {
    position: relative;
  }
  .video_button_big {
    display: none;
  }
  .blog_img {
    width: 100%;
  }
}


.content {
  position: relative;
  gap: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: fit-content;
  flex-wrap: wrap;
}




.post-content {
  overflow-wrap: break-word;
  width: 45vw;
  min-width: 330px;
  max-width: 400px;
}
</style>