<template>
    <div v-if="bgs[0]"> 
        <div class="frame70">
            <Transition appear name="fade">
                
                <div class="parent">
                    <Transition appear name="fade">
                    <video class="video" :key="selected" autoplay muted loop id="myVideo">
                        <source :src="bgs[0].acf.background_video" type="video/mp4">
                    </video>
                    </Transition>
                     <div id="overlay" />
                </div>
            </Transition>

            <Transition appear name="fade">
                <div id="floater">
                    <div id="opacity-wrapper" class="container">
                        <h1 class="main-title">Simon<span style="color:#931F18">Kr</span>
                            <h2 class="main-subtitle">Video Production Company</h2>
                        </h1>
                    </div>
                </div>
            </Transition>
        </div>

        <div class="nav-wrapper">
            <Transition appear name="fade">
                <div class="home-carousel">
                    <!-- <img @click="selected = 0" v-if="selected != 0" class="carousel-circle"
                        :src="this.$baseUrl + '/icons/icon-circle.png'">
                    <img v-else class="carousel-circle" :src="this.$baseUrl + '/icons/icon-circle-dim.png'">
                    <img @click="selected = 1" v-if="selected != 1" class="carousel-circle"
                        :src="this.$baseUrl + '/icons/icon-circle.png'">
                    <img v-else class="carousel-circle" :src="this.$baseUrl + '/icons/icon-circle-dim.png'">
                    <img @click="selected = 2" v-if="selected != 2" class="carousel-circle"
                        :src="this.$baseUrl + '/icons/icon-circle.png'">
                    <img v-else class="carousel-circle" :src="this.$baseUrl + '/icons/icon-circle-dim.png'">
                    <img @click="selected = 3" v-if="selected != 3" class="carousel-circle"
                        :src="this.$baseUrl + '/icons/icon-circle.png'">
                    <img v-else class="carousel-circle" :src="this.$baseUrl + '/icons/icon-circle-dim.png'">
                    <img @click="selected = 4" v-if="selected != 4" class="carousel-circle"
                        :src="this.$baseUrl + '/icons/icon-circle.png'">
                    <img v-else class="carousel-circle" :src="this.$baseUrl + '/icons/icon-circle-dim.png'"> -->
                </div>
            </Transition>

            <div id="lower-nav" class="nav-bar container">
                <div />
                <div>
                    <a href="https://www.gettyimages.com/search/photographer?photographer=simonkr" target="_blank">
                        <img class="icon-medium" :src="this.$baseUrl + '/icons/getty-logo.png'">
                    </a>
                    <a href="https://www.istockphoto.com/portfolio/simonkr?mediatype=photography" target="_blank">
                        <img class="icon-small" :src="this.$baseUrl + '/icons/istock-logo.png'">
                    </a>
                </div>
            </div>
        </div>

        <portfolio-card @isloaded="loaded = true"/>
        <video-card />
        <blog-card />
        <rental-card />
        <footer-element-dark v-if="loaded"/>
    </div>
</template>
  
<script>
import axios from 'axios'
import portfolioCard from '../components/cards/portfolioCard.vue'
import blogCard from '../components/cards/blogCard.vue'
import rentalCard from '../components/cards/rentalCard.vue'
import videoCard from '../components/cards/videoCard.vue'
import footerElementDark from '@/components/footerDark.vue'

export default {
    name: 'homePage',

    data() {
        return {
            bgs: [],
            selected: -1,
            loaded: false
        }
    },
    created() {
        this.getData();
    },
    mounted() {
        window.onscroll = function () {
            myFunction();
        };
        

        var navbar = document.getElementById("navbar");

        function myFunction() {
            if (window.pageYOffset >= 60) {
                navbar.classList.add("sticky")
            } else {
                navbar.classList.remove("sticky");
            }
        }

        
        window.addEventListener("scroll", function () {
            this.parallax();
        }.bind(this));
    },
    methods: {
        async getData() {
            try {
                let response = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/background");
                this.bgs = await response.data;
                console.log(this.bgs[0]);
            } catch (error) {
                console.log(error);
            }
        },
        parallax() {
            var s = document.getElementById("floater");
            var so = document.getElementById("opacity-wrapper");
            var o = document.getElementById("overlay");
            var vc = document.getElementById("vc");
            var bc = document.getElementById("bc");
            var wv = document.querySelector(".wrapper-video");
            var pc = document.getElementById("pc");
            var homeCarousel = document.querySelector(".home-carousel");

            var differ = window.outerHeight / 1000;

            var yPos = 0 - window.pageYOffset / 16;
            if(s != null && so != null && o != null && vc != null && bc != null && wv != null && pc != null && homeCarousel != null) {
                o.style.opacity = 50 + window.pageYOffset / 16+ "%";
                // s.style.top = -yPos / differ + "%";

                if (window.pageYOffset > 0) {
                    homeCarousel.style.opacity = "0";
                } else {
                    homeCarousel.style.opacity = "100%";
                }
                if (window.pageYOffset > 300) {
                    so.style.opacity = "0";
                } else {
                    so.style.opacity = "100%";
                }
                if (window.innerWidth > 480) {
                    if (window.pageYOffset < 1900) {
                        if (vc) {
                            vc.style.marginTop = Math.max(-50, 100 + yPos) + "%"
                        }
                    } else if (window.pageYOffset < 2500) {
                        bc.style.marginTop = Math.max(-70 / differ, 120 + yPos) + "%"
                    }

                    if (window.pageYOffset > 1500 && window.pageYOffset < 1700) {
                        document.getElementById("one").style.opacity = "100%";
                        pc.style.opacity = "0"
                    } else if (window.pageYOffset > 1700 && window.pageYOffset < 2050) {
                        document.getElementById("one").style.opacity = "100%";
                        document.getElementById("two").style.opacity = "100%";
                        wv.style.opacity = "100%"
                    } else if (window.pageYOffset > 2150 && window.pageYOffset < 2300) {
                        document.getElementById("one").style.opacity = "0%";
                        document.getElementById("two").style.opacity = "0%";

                        wv.style.opacity = "0%"
                    } else if (window.pageYOffset > 2300 + differ && window.pageYOffset < 3100 + differ) {
                        document.getElementById("bc-cont").style.opacity = "100%";
                    } else if (window.pageYOffset < 1500) {
                        pc.style.opacity = "100%"
                        if (document.getElementById("bc-cont")) {
                            document.getElementById("bc-cont").style.opacity = "100%";
                            document.getElementById("one").style.opacity = "0%";
                            document.getElementById("two").style.opacity = "0%";
                        }
                    }
                } else {
                    document.getElementById("one").style.opacity = "100%";
                    document.getElementById("two").style.opacity = "100%";
                    wv.style.opacity = "100%"
                    document.querySelector(".wrapper_2").style.paddingBottom = "0px";
                    document.getElementById("vc").style.marginBottom = "60px";
                    pc.style.opacity = "100%"
                }
            }
        }
    },
    components: {
        portfolioCard,
        blogCard,
        rentalCard,
        videoCard,
        footerElementDark
    }
}
</script>
  
<style lang="scss" scoped>
@media (max-width: 480px) {
    #lower-nav {
        display: none;
    }
    .main-title {
        text-align: center;
    }
    .main-subtitle {
        text-align: center;
        padding-bottom: 30px;
    }
}
#opacity-wrapper {
    transition: 300ms;
}

.home-carousel {
    position: absolute;
    left: calc(50% - 45px);
    margin-top: -40px;
    transition: 200ms;
}

.carousel-circle {
    width: 13px;
    margin-right: 5px;
    cursor: pointer;
}

#floater {
    position: absolute;
    bottom: 60px;
    height: fit-content;
    width: 100%;
    z-index: 600;
}

.frame70 {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    // left: 0px;
    // top: 0px;
}

.parent {
    z-index: -5;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0rem;
    right: 0rem;
    bottom: 0rem;
    top: 0rem;

    // left: 50%;
    /* % of surrounding element */
    // top: 50%;
    // transform: translate(-50%, -50%);
    /* % of current element */
    overflow: hidden;
}

.video {
    height: 100%;
    width: 100%;
    min-width: 100%;
    // min-height: 56.25vw;
    object-fit: cover;
    /* 100 * 9 / 16 */
}


#overlay {
    background-color: rgb(15, 15, 15);
    opacity: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
}

.main-title {
    color: white;
    font-family: var(--font-family-inter);
    font-size: 60px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
}

.main-subtitle {
    color: white;
    font-family: var(--font-family-inter);
    font-size: var(--font-size-l);
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
}
</style>