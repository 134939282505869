<template>
    <div class="button-4 inter-medium-alto-m">
        <span v-html="content"></span>
    </div>
</template>

<script>
export default {
    name: "buttonDarkBig",
    props: ["content"]
}
</script>

<style>
.button-4 {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 20px;
    gap: 10px;
    width: fit-content;
    background: black;
    position: relative;
    border: 2px solid black;
    border-radius: 100px;
    transition: 0.3s;
    cursor: pointer;
}
.button-4:hover {
    background-color: #F3F3F3;
    color: black;
}
</style>